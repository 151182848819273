@import "settings/settings";
/**
 * Foundation for Sites by ZURB
 * Version 6.2.0
 * foundation.zurb.com
 * Licensed under MIT Open Source
 */

// Sass utilities
@import 'util/util';

// Global variables and styles
@import 'global';

// Components
@import 'grid/grid';
@import 'typography/typography';

@import 'components/visibility';
@import 'components/float';
@import 'components/button';










@import 'components/flex';




@import 'components/menu-icon';




@import 'components/reveal';










@mixin foundation-everything($flex: false) {
  @if $flex {
    $global-flexbox: true !global;
  }

  @include foundation-global-styles;
  @if not $flex {
    @include foundation-grid;
  }
  @else {
    @include foundation-flex-grid;
  }
  @include foundation-typography;
  
  @include foundation-button;
  
  
  
  
  
  
  
  
  @include foundation-menu-icon;
  
  
  
  
  
  
  
  
  
  
  
  
  @include foundation-reveal;
  
  
  
  
  
  
  
  
  

  @if $flex {
    @include foundation-flex-classes;
  }
}



